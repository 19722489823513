import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/number-input/accessibility",
  "title": "NumberInput - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`HDS number input allows the user to input the date manually without using stepper actions. Whenever you require the user to input numbers, you should also allow manual input.`}</li>
      <li parentName="ul">{`If steppers are used, provide descriptive aria labels for both increase and decrease actions by using `}<inlineCode parentName="li">{`minusStepButtonAriaLabel`}</inlineCode>{` and `}<inlineCode parentName="li">{`plusStepButtonAriaLabel`}</inlineCode>{` props.`}</li>
      <li parentName="ul">{`If the number needs to follow a specific format, remember to specify it in the assistive text.`}</li>
      <li parentName="ul">{`Placeholders in number inputs should be avoided. Different screen readers announce placeholders in different ways. It can be very difficult for a screen reader user to differentiate the placeholder from the actual value.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      